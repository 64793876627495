<template>
  <mode-aggregate-logs-card />
</template>
<script>
import ModeAggregateLogsCard from "./components/ModeAggregateLogsCard.vue";
export default {
  components: {
    ModeAggregateLogsCard,
  },
};
</script>

export default [
  { value: "Any", key: -999 },
  { value: "MagicBust", key: 1 },
  { value: "OlymposGods", key: 2 },
  { value: "CosmicJelly", key: 3 },
  { value: "LuckyClover", key: 4 },
  { value: "CandyCrown", key: 5 },
  { value: "Japanese", key: 6 },
  { value: "ViktoriesOfZeus", key: 7 },
  { value: "PirateBounty", key: 8 },
  { value: "777 Classic", key: 9 },
  { value: "SuperVegas", key: 10 },
  { value: "JuicyFruits", key: 11 },
  { value: "WildWestDuel", key: 12 },
  { value: "XmashCash", key: 13 },
  { value: "GoldenFrog", key: 14 },
  { value: "DonutBakery", key: 15 },
  { value: "AquaParadise", key: 16 },
  { value: "WildForest", key: 17 },
  { value: "MountainOfRiches", key: 18 },
  { value: "CleopatraPlace", key:19  },
  { value: "GalacticOdyssey", key:20  },
  { value: "ZombieNight", key:21  },
  { value: "CashMafia", key:22 },
  { value: "CocktailParty", key:23  },
  { value: "VikingFury", key:24  },
  { value: "HalloweenMadness", key:25 },
  { value: "LuckMoney", key:26 },
  { value: "DawnOfRa", key:27  },
  { value: "AstroQuest", key:28 },
  { value: "LuckyHarvest", key:29  },
  { value: "LuxurySisters", key:30  },
  { value: "Enchanted Village", key:31  },
  { value: "ChinaTown", key:32  },
  { value: "FishermanFortune", key:33  },
  { value: "GloryOfGreece", key:34  },
  { value: "MysteriousWoods", key:35 }

  // { value: "Any", key: -999 },
  // { value: "Elite", key: -2 },
  // { value: "Holdem", key: 0 },
  // { value: "Omaha", key: 1 },
  // { value: "Trio", key: 2 },
  // { value: "HighCardFlush", key: 3 },
  // { value: "Ultimate", key: 4 },
  // { value: "KingOfTable", key: 5 },
  // { value: "KingOfOmaha", key: 6 },
  // { value: "KingSlotRoyal", key: 7 },
  // { value: "KingRoyal", key: 8 },
  // { value: "KingSlot", key: 9 },
  // { value: "Turbo", key: 10 },
  // { value: "Royal", key: 11 },
  // { value: "SlotRoyal", key: 12 },
  // { value: "Slot", key: 13 },
  // { value: "Black", key: 14 },
  // { value: "Red", key: 15 },
  // { value: "Jump", key: 16 },
  // { value: "Spin", key: 17 },
  // { value: "Arena", key: 18 },
  // { value: "BingoTexas", key: 19 },
  // { value: "BingoOmaha", key: 20 },
  // { value: "BingoTrio", key: 21 }
];
